<script setup lang="ts">
import type { RouteLocationRaw } from '#vue-router';
import SectionContainer from '../SectionContainer/SectionContainer.vue';

type News = {
  date: string;
  title: string;
  location: RouteLocationRaw;
}[];

const news: News = [
  // {
  //   date: '2024年6月3日',
  //   title:
  //     '円安、物価高、老後資金、新NISAなど…お金の悩みが尽きない方へ。初書籍 『お金の悩みがなくなる！ オカネコ家計教室』2024年6月3日（月）発売',
  //   location: '/chat/diagnosis',
  // },
];
</script>

<template>
  <SectionContainer v-if="news.length > 0">
    <div :class="$style.table">
      <template v-for="n in news" :key="n">
        <div :class="$style.table__date">{{ n.date }}</div>
        <NuxtLink :class="$style.table__title" :href="n.location">{{
          n.title
        }}</NuxtLink>
      </template>
    </div>
  </SectionContainer>
</template>

<style lang="scss" module>
.table {
  display: grid;
  background-color: $color-surface-secondary;
  padding: 24px;
  border-radius: 8px;
  gap: 24px;
  grid-template-columns: max-content 1fr;
  max-width: 100%;

  @include mobile-only {
    display: flex;
    flex-direction: column;
    padding: 12px;
    gap: 12px;
  }

  &__date {
    grid-column: 1;
    font-size: 12px;
    align-self: center;
    @include mobile-only {
      align-self: unset;
    }
  }

  &__title {
    grid-column: 2;
    font-size: 14px;
    text-decoration: underline;
    align-self: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @include mobile-only {
      display: flex;
      white-space: unset;
    }
  }
}
</style>
