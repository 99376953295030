<script setup lang="tsx">
import SectionContainer from '../SectionContainer/SectionContainer.vue';
import PlannerCard, { type Props } from './PlannerCard.vue';
import ImgPlanner1 from '/home/runner/_work/shadow/shadow/packages/app/node_modules/.cache/home/runner/_work/shadow/shadow/packages/app/src/components/templates/IndexTemplate/IndexPlanners/planner1.jpg/planner1.webp';
import ImgPlanner2 from '/home/runner/_work/shadow/shadow/packages/app/node_modules/.cache/home/runner/_work/shadow/shadow/packages/app/src/components/templates/IndexTemplate/IndexPlanners/planner2.jpg/planner2.webp';
import ImgPlanner3 from '/home/runner/_work/shadow/shadow/packages/app/node_modules/.cache/home/runner/_work/shadow/shadow/packages/app/src/components/templates/IndexTemplate/IndexPlanners/planner3.jpg/planner3.webp';
import ImgPlanner4 from '/home/runner/_work/shadow/shadow/packages/app/node_modules/.cache/home/runner/_work/shadow/shadow/packages/app/src/components/templates/IndexTemplate/IndexPlanners/planner4.jpg/planner4.webp';
import ImgPlanner5 from '/home/runner/_work/shadow/shadow/packages/app/node_modules/.cache/home/runner/_work/shadow/shadow/packages/app/src/components/templates/IndexTemplate/IndexPlanners/planner5.jpg/planner5.webp';
import type { Component } from 'vue';

const planners: {
  imgPath: string;
  body: string | Component<unknown>;
  props: Props;
}[] = [
  {
    imgPath: ImgPlanner1,
    body: 'お金とうまく付き合うための第一歩は、自分の送りたい人生を描くことです。私は、お金は手段だと常々考えています。だから最初に、自分はなにをしたいのかどんな人生を生きていきたいのかというビジョンを描くところからまずは一緒にはじめていきましょう。「これから」を見据えたお金との心地よい関係について一緒に考えていけたら幸いです。',
    props: {
      name: '金子 智',
      appeal: '落語仕込みのわかりやすい説明が好評！',
      tags: [
        '家計改善',
        'ライフプランニング',
        '教育資金',
        '保険',
        '資産運用',
        '老後資金',
        '相続',
        '投資/お金の勉強',
      ],
    },
  },
  {
    imgPath: ImgPlanner2,
    body: (_) => (
      <div>
        株式会社400Fに所属しております、金融オンラインアドバイザーの松本結衣と申します。
        <br />
        お金に対する漠然とした不安はあるものの、なかなか行動に移せずにいる方はたくさんいらっしゃるかと思います。オカネコを通して、気軽な気持ちで一歩踏み出して頂けると嬉しいです。お客様の不安やお悩みの解決に向けて、誠心誠意お手伝いをさせていただきます。
      </div>
    ),
    props: {
      name: '松本 結衣',
      appeal: '住宅購入の相談はお任せください',
      tags: [
        '家計改善',
        'ライフプランニング',
        '教育資金',
        '保険',
        '資産運用',
        '住宅ローン',
        '老後資金',
        '不動産',
        '投資/お金の勉強',
      ],
    },
  },
  {
    imgPath: ImgPlanner3,
    body: (_) => (
      <div>
        お客様の思いや価値観、そして背景を何よりも大切にし、わかりやすく丁寧なご説明を心がけています。
        <br />
        今抱えている不安や思い、そして目標や夢をぜひお聞かせください。人生をより楽しく、充実したものにするために、お金の不安がない環境を一緒に築いていきましょう。お客様の目標や夢の実現をサポートし、明るい未来へと進むお手伝いができれば嬉しいです。
      </div>
    ),
    props: {
      name: '大野 慶吾',
      appeal: 'お客様の思いや価値観を大切にします',
      tags: [
        '家計改善',
        'ライフプランニング',
        '教育資金',
        '保険',
        '資産運用',
        '老後資金',
        '相続',
        '投資/お金の勉強',
      ],
    },
  },
  {
    imgPath: ImgPlanner4,
    body: (_) => (
      <div>
        “思い立ったが吉日”という言葉があるように、何かを始めるのに遅すぎることはありません。「お金のことで悩んでいる」「お金のことを相談したい」その思いがある時点で、すでにお金と真剣に向き合えています。それはとても素敵なことです。
        <br />
        お客様一人ひとりに合ったプランをご提案いたしますので、どうぞお気軽にご相談ください。今日が人生で一番若い日。一緒にお金の悩みを解決していきましょう。
      </div>
    ),
    props: {
      name: '軽部 良典',
      appeal: '豊富な知識で悩み・不安をサポート',
      tags: [
        '家計改善',
        'ライフプランニング',
        '保険',
        '相続',
        '教育資金',
        '住宅ローン',
        '資産運用',
        '老後資金',
      ],
    },
  },
  {
    imgPath: ImgPlanner5,
    body: (_) => (
      <div>
        お金の不安を挙げればキリがありません。学校ではお金について学ぶ機会が少ないため、転勤・転職・結婚・出産など、人生の大きな節目で不安を感じるのは当然のことです。
        <br />
        体の調子が悪ければ医師に、法律の問題があれば弁護士に相談するように、私は「一家に一人、お金のかかりつけ医」が必要だと考えています。お金に不安を感じたときは、どうぞお気軽にご相談ください。
      </div>
    ),
    props: {
      name: '伊藤 祐助',
      appeal: '金融の仕事を通してお客様の人生を豊かに',
      tags: [
        '家計改善',
        'ライフプランニング',
        '教育資金',
        '保険',
        '資産運用',
        '住宅ローン',
        '老後資金',
        '相続',
        '不動産',
        '投資/お金の勉強',
      ],
    },
  },
];
</script>

<template>
  <div>
    <SectionContainer
      heading="あなたに合うアドバイザーが見つかる!"
      subheading="online advisor"
    >
      <template #description>
        <div :class="$style.header__body">
          オカネコ専任の金融オンライン・アドバイザーに加え、<br />
          保険代理店・IFA事業者など250社と連携し、経験豊富な600名以上のお金のプロに相談できます。
        </div>
        <div :class="$style.header__notice">
          ※ 2024年6月24日時点当社データベースより概算
        </div>
      </template>
    </SectionContainer>
    <div :class="$style.planners">
      <template v-for="n in 3" :key="n">
        <PlannerCard
          v-for="p in planners"
          :key="`${p.imgPath}:${n}`"
          v-bind="p.props"
        >
          <template #avatar><img :src="p.imgPath" /></template>
          <template v-if="typeof p.body === 'string'">{{ p.body }}</template>
          <component :is="p.body" v-else />
        </PlannerCard>
      </template>
    </div>
  </div>
</template>

<style module lang="scss">
.planners {
  display: flex;
  width: v-bind('`${(360 + 24) * planners.length * 3}px`');
  gap: 24px;

  animation: 40s linear 0s infinite running carousel;
  @keyframes carousel {
    to {
      transform: translateX(v-bind('`-${(360 + 24) * planners.length}px`'));
    }
  }
}
.header {
  &__body {
    text-align: center;
  }
  &__notice {
    margin-top: 16px;
    font-size: 10px;
  }
}

.planners {
  margin-top: 24px;
}

.planner {
  &__listing {
    list-style: disc;
    padding-left: 1.5em;
  }
}
</style>
