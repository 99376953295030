<script setup lang="ts">
import SectionContainer from '../SectionContainer/SectionContainer.vue';
import InterviewCard from '~/components/organisms/InterviewCard/InterviewCard.vue';

const interviewIds = ['1', '2', '3', '4'] as const;
</script>

<template>
  <SectionContainer heading="みんなの体験談" subheading="interview">
    <template #description
      >実際にサービスを利用した人のリアルな声をお届けします。</template
    >
    <div :class="$style.reviews">
      <InterviewCard
        v-for="i in interviewIds"
        :id="i"
        :key="i"
        variant="complicated"
      />
    </div>
    <p :class="$style.reviews__notice">
      ※本レビューの執筆に際し、謝礼をお渡ししました。この対価はレビューの執筆に対する感謝を表すものであり、レビューの内容や意見に影響を与えるものではありません<br />
      ※内容は、個々の体験や意見に基づいています。お客様の個人的な意見であり、必ずしも全ての方に当てはまるものではありません
    </p>
  </SectionContainer>
</template>

<style module lang="scss">
.reviews {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  margin: 16px 0 40px;

  @include mobile-only {
    grid-template-columns: 1fr;
  }

  &__notice {
    font-size: 10px;
    text-align: center;
    line-height: 1.8;
    @include mobile-only {
      text-align: start;
    }
  }
}
</style>
