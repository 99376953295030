<script setup lang="ts">
import SectionContainer from '../SectionContainer/SectionContainer.vue';
import FAQItem, { type Props } from './FAQItem.vue';

const items: Props[] = [
  {
    q: '無料で利用できますか？',
    a: 'オカネコは無料でご利用いただけます。オカネコは複数の提携企業より報酬をいただいて運営しているので、お客様から費用をいただくことは一切ございません。何度でも無料でご相談いただけますので、お気軽にご利用ください。',
  },
  {
    q: 'どんなことが家計診断でわかるのですか？',
    a: '質問項目に答えると、100万件超のデータをもとに年収、金融資産、毎月の貯金額を「家族構成 ✕ 居住エリア」が同じ世帯と比較できます。また、簡易的なライフプランを確認することもでき、将来的な資産の推移もチェックすることができます。',
  },

  {
    q: '本当に自分に合ったものを紹介してもらえるのですか？',
    a: 'オカネコには株や債券、投資信託、生命保険や住宅ローンなど様々な商品のプロであるアドバイザーが在籍しており、中立的な視点からワンストップで相談に対応できることが最大の強みです。複数のアドバイザーのコメントを比較したり、セカンドオピニオンのように利用していただいても構いません。',
  },
  {
    q: 'しつこく営業をされないか不安です',
    a: 'お客様から具体的なご依頼がない限りは、アドバイザーからお客様へ商品を提案することはございませんのでご安心ください。',
  },
];

useJsonld(
  {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: items.map((qa) => ({
      '@type': 'Question',
      name: qa.q,
      acceptedAnswer: {
        '@type': 'Answer',
        text: qa.a,
      },
    })),
  },
  {
    tagPosition: 'bodyClose',
  }
);
</script>

<template>
  <SectionContainer
    heading="よくある質問"
    subheading="Frequently Asked Questions"
  >
    <div :class="$style.items">
      <FAQItem v-for="i in items" :key="i.q" :class="$style.item" v-bind="i" />
    </div>
  </SectionContainer>
</template>

<style module lang="scss">
.items {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: stretch;
}
</style>
