<script setup lang="ts">
import Feature4Body from './Feature4Body.vue';
import type { FeatureProps } from './ServiceFeature.vue';
import ServiceFeature from './ServiceFeature.vue';
import ImgPcBackgroundLeftTop from './bg-pc-lefttop.svg?component';
import ImgPcBackgroundRightBottom from './bg-pc-rightbottom.svg?component';
import ImgSpBackground from './bg-sp.svg?component';
import ImgFeature1 from './img_feature_01.svg';
import ImgFeature2 from './img_feature_02.svg';
import ImgFeature3 from './img_feature_03.svg';
import ImgFeature4 from './img_feature_04.svg';
import type { Component } from 'vue';

const featureBody1 = () =>
  h('span', [
    '無料＆実名・住所入力は不要で家計診断！',
    h('br'),
    'お金に関するお悩みならなんでも相談できます。',
  ]);

const features: { body: string | Component<unknown>; props: FeatureProps }[] = [
  {
    body: featureBody1,
    props: {
      imgPath: ImgFeature1,
      heading: '簡単・気軽に診断',
    },
  },
  {
    body: 'アドバイザーを自分で選べるだけでなく、家計診断、チャット相談、AI相談、セミナー参加などから自分に合ったサービスを自分のペースで利用できます。',
    props: {
      imgPath: ImgFeature2,
      heading: '自分に合った方法で',
    },
  },
  {
    body: '匿名のクチコミ評価に加え、法令に基づき苦情処理措置及び紛争解決措置を講じています。また悪質案件を公表し、安心してサービスを利用できる環境構築に努めます。',
    props: {
      imgPath: ImgFeature3,
      heading: '安心・安全',
    },
  },
  {
    body: Feature4Body,
    props: {
      imgPath: ImgFeature4,
      heading: 'お客様本位',
    },
  },
];
</script>

<template>
  <div>
    <p :class="$style.heading">特徴</p>
    <div :class="$style.background">
      <div :class="$style.features">
        <ServiceFeature
          v-for="f in features"
          :key="f.props.heading"
          v-bind="f.props"
        >
          <template v-if="typeof f.body === 'string'">
            {{ f.body }}
          </template>
          <component :is="f.body" v-else />
        </ServiceFeature>
      </div>
      <ImgPcBackgroundLeftTop
        :class="[
          $style.background__image,
          $style['background__image--lefttop'],
        ]"
      />
      <ImgPcBackgroundRightBottom
        :class="[
          $style.background__image,
          $style['background__image--rightbottom'],
        ]"
      />
      <ImgSpBackground
        :class="[$style.background__image, $style['background__image--sptop']]"
      />
      <ImgSpBackground
        :class="[
          $style.background__image,
          $style['background__image--spbottom'],
        ]"
      />
    </div>
  </div>
</template>

<style module lang="scss">
.heading {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
}
.features {
  margin-top: 32px;
  width: 100%;
  max-width: 1120px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 24px;
}

.background {
  position: relative;
  @include create-stacking-context;
  &__image {
    position: absolute;
    z-index: -1;
    &--lefttop {
      left: -80px;
      top: -24px;
      @include mobile-only {
        display: none;
      }
    }
    &--rightbottom {
      right: -80px;
      bottom: 56px;
      @include mobile-only {
        display: none;
      }
    }
    &--sptop {
      width: 100%;
      max-width: 360px;
      display: none;
      top: -100px;
      left: 50%;
      transform: translateX(-50%);
      @include mobile-only {
        display: unset;
      }
    }
    &--spbottom {
      width: 100%;
      max-width: 360px;
      display: none;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      @include mobile-only {
        display: unset;
      }
    }
  }
}
</style>
