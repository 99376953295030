<script setup lang="ts">
import Img1 from '/home/runner/_work/shadow/shadow/packages/app/node_modules/.cache/home/runner/_work/shadow/shadow/packages/app/src/components/templates/IndexTemplate/IndexHero/DiagnosisExamplesLoop/1.png/1.webp';
import Img2 from '/home/runner/_work/shadow/shadow/packages/app/node_modules/.cache/home/runner/_work/shadow/shadow/packages/app/src/components/templates/IndexTemplate/IndexHero/DiagnosisExamplesLoop/2.png/2.webp';
import Img3 from '/home/runner/_work/shadow/shadow/packages/app/node_modules/.cache/home/runner/_work/shadow/shadow/packages/app/src/components/templates/IndexTemplate/IndexHero/DiagnosisExamplesLoop/3.png/3.webp';
import Img4 from '/home/runner/_work/shadow/shadow/packages/app/node_modules/.cache/home/runner/_work/shadow/shadow/packages/app/src/components/templates/IndexTemplate/IndexHero/DiagnosisExamplesLoop/4.png/4.webp';
import Img5 from '/home/runner/_work/shadow/shadow/packages/app/node_modules/.cache/home/runner/_work/shadow/shadow/packages/app/src/components/templates/IndexTemplate/IndexHero/DiagnosisExamplesLoop/5.png/5.webp';
import Img6 from '/home/runner/_work/shadow/shadow/packages/app/node_modules/.cache/home/runner/_work/shadow/shadow/packages/app/src/components/templates/IndexTemplate/IndexHero/DiagnosisExamplesLoop/6.png/6.webp';
import Img7 from '/home/runner/_work/shadow/shadow/packages/app/node_modules/.cache/home/runner/_work/shadow/shadow/packages/app/src/components/templates/IndexTemplate/IndexHero/DiagnosisExamplesLoop/7.png/7.webp';
import Img8 from '/home/runner/_work/shadow/shadow/packages/app/node_modules/.cache/home/runner/_work/shadow/shadow/packages/app/src/components/templates/IndexTemplate/IndexHero/DiagnosisExamplesLoop/8.png/8.webp';
import Img9 from '/home/runner/_work/shadow/shadow/packages/app/node_modules/.cache/home/runner/_work/shadow/shadow/packages/app/src/components/templates/IndexTemplate/IndexHero/DiagnosisExamplesLoop/9.png/9.webp';

const images = [Img1, Img2, Img3, Img4, Img5, Img6, Img7, Img8, Img9];
const gap = 8;
const imageWidth = 190;
const slide = -(gap + imageWidth) * images.length;
</script>

<template>
  <div :class="$style.slide">
    <!-- 4Kディスプレイだと4回繰り返さないと途切れる -->
    <template v-for="n in 4">
      <img
        v-for="src in images"
        :key="`${n}:${src}`"
        :class="$style.images__image"
        :src="src"
        :width="imageWidth"
        height="258"
      />
    </template>
  </div>
</template>

<style module lang="scss">
.slide {
  padding: 8px 0;
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;
  animation: 40s linear 0s infinite running carousel;
  @keyframes carousel {
    to {
      transform: translateX(v-bind('`${slide}px`'));
    }
  }
}

.images {
  display: flex;
  flex-wrap: nowrap;
  gap: v-bind('`${gap}px`');
  &__image {
    width: v-bind('`${imageWidth}px`');
    filter: drop-shadow(0 4px 12px $color-shadow)
      drop-shadow(0 0 1px $color-shadow-outline);
  }
}
</style>
