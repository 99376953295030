<script setup lang="ts">
import UsageCard, { type CardProps } from './UsageCard.vue';
import ImgStep1 from './img_step01.svg';
import ImgStep2 from './img_step02.svg';
import ImgStep3 from './img_step03.svg';

const cards: CardProps[] = [
  {
    step: 'ステップ1',
    heading: 'まずはお金の健康診断',
    description:
      '約20問の家計に関する質問に答えていきましょう。約3分で完了します。情報は非公開・匿名なので安心です。',
    imgPath: ImgStep1,
  },
  {
    step: 'ステップ2',
    heading: '診断結果がすぐわかる',
    description:
      '自分と同じエリア、同じ年代の人と比較した評価と簡単な家計改善アドバイスをお届けします。簡易ライフプランも確認できます。',
    imgPath: ImgStep2,
  },
  {
    step: 'ステップ3',
    heading: 'お金のプロに無料で相談できる',
    description:
      '診断結果をふまえた具体的なアドバイスが複数のアドバイザーから届きます。内容を比較し、自分に合ったアドバイザーを見つけましょう。',
    imgPath: ImgStep3,
  },
];
</script>

<template>
  <div>
    <div :class="$style.heading">使い方</div>
    <div :class="$style.cards">
      <UsageCard
        v-for="c in cards"
        :key="c.step"
        :class="$style.cards__card"
        v-bind="c"
      />
    </div>
  </div>
</template>

<style lang="scss" module>
.heading {
  padding: 28px;
  text-align: center;
  font-size: 28px;
  font-weight: bold;

  @include mobile-only {
    font-size: 20px;
  }
}
.cards {
  display: flex;
  gap: 40px;

  &__card {
    flex: 1;
  }

  @include mobile-only {
    flex-direction: column;
  }
}
</style>
