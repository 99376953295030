<template>
  <NuxtLink :class="$style.button" href="/chat/diagnosis"
    >診断をはじめる</NuxtLink
  >
</template>

<style module lang="scss">
.button {
  line-height: 1em;
  padding: 18px 24px;
  font-size: 16px;
  font-weight: bold;
  color: $color-text-and-object-high-emphasis-inverse;
  border-radius: 6px;
  background: linear-gradient(94deg, #ff5e00 0%, #ffc412 100%);
}
</style>
