<script setup lang="ts">
import DiagnosisExamplesLoop from './DiagnosisExamplesLoop/DiagnosisExamplesLoop.vue';
import RenewalBadge from './RenewalBadge.vue';
import CoerceSsrRendering from '~theme/components/atoms/CoerceSsrRendering.vue';
import PillShapedSpan from '~theme/components/atoms/PillShapedSpan.vue';

const { isDesktop } = useDeviceType();
</script>

<template>
  <section>
    <div :class="$style.wrapper">
      <div :class="$style.catchcopy">
        <div :class="$style.heading">
          <p>
            <PillShapedSpan :class="$style.heading__pill"
              >世帯年収</PillShapedSpan
            >
            <PillShapedSpan :class="$style.heading__pill"
              >金融資産</PillShapedSpan
            >
            を<br />
          </p>
          <div :class="$style.heading__emText">
            <p>エリア平均と比較</p>
          </div>
          <div :class="$style.heading__badge">
            <RenewalBadge />
          </div>
        </div>
        <p :class="$style.description">
          年収、金融資産、毎月の貯金額を<br
            :class="$style.description__mobile"
          />
          <span :class="$style.description__emText"
            >「家族構成 ✕ 居住エリア」</span
          >が同じ世帯と比較できます！<br />
          <span :class="$style.description__emText">100万件超のデータ</span
          ><span :class="$style.description__smallText"> (※2) </span
          >を元にお金の健康診断してみませんか？
        </p>
      </div>
      <!-- SSR時は常にレンダリングしてCSSで隠すことでレイアウトがパカパカするのを防ぐ -->
      <!-- アニメーションしててアレなのでCSR時にDOMから落とす -->
      <CoerceSsrRendering :predicate="!isDesktop">
        <div :class="[$style.carousel, $style['carousel--notDesktop']]">
          <RenewalBadge :class="$style.carousel__badge" />
          <DiagnosisExamplesLoop />
        </div>
      </CoerceSsrRendering>

      <div :class="$style.buttons">
        <NuxtLink :class="$style.button" :to="{ path: '/chat/diagnosis' }">
          <span :class="[$style.button__balloon]">3分で完了</span>
          無料であなたのお金の健康を診断<br />
          <strong :class="$style.button__strong">今すぐ診断する</strong>
        </NuxtLink>
      </div>
    </div>
    <!-- SSR時は常にレンダリングしてCSSで隠すことでレイアウトがパカパカするのを防ぐ -->
    <!-- アニメーションしててアレなのでCSR時にDOMから落とす -->
    <CoerceSsrRendering :predicate="isDesktop">
      <div :class="[$style.carousel, $style['carousel--desktop']]">
        <DiagnosisExamplesLoop />
      </div>
    </CoerceSsrRendering>
  </section>
</template>

<style lang="scss" module>
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 32px 0 16px;
  flex-direction: column;
  @include desktop-only {
    padding-top: 40px;
    flex-direction: row;
    gap: 80px;
    margin: 0 12px;
  }
}

.heading {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  width: fit-content;
  @include desktop-only {
    font-size: 24px;
    align-items: flex-start;
  }

  &__pill {
    background-color: $color-surface-accent-primary;
    color: $color-text-and-object-high-emphasis-inverse;
    padding: 4px 16px;

    &:nth-child(n + 2) {
      margin-left: 8px;
    }
  }

  &__emText {
    font-weight: 900;
    font-size: 38px;
    @include desktop-only {
      margin-top: 22px;
      margin-bottom: 12px;
      line-height: 1em;
      font-size: 48px;
    }
  }

  &__badge {
    position: absolute;
    bottom: 0;
    left: 100%;
    transform: translateX(12px);
    display: none;
    @include desktop-only {
      display: block;
    }
  }
}

.description {
  font-size: 12px;
  word-break: keep-all;
  text-align: center;
  margin-top: 8px;
  line-height: 1.8em;
  @include desktop-only {
    font-size: 16px;
    text-align: start;
  }
  &__emText {
    font-weight: 900;
  }
  &__smallText {
    font-size: 10px;
    @include desktop-only {
      font-size: 12px;
    }
  }

  &__mobile {
    display: none;

    @include mobile-only {
      display: inline;
    }
  }
}

.buttons {
  padding-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 320px;
  width: 100%;

  @include create-stacking-context;
}

.button {
  position: relative;
  display: block;
  width: 100%;
  padding: 20px 12px;
  font-size: 12px;
  line-height: 1em;
  color: $color-text-and-object-high-emphasis-inverse;
  text-align: center;
  border-radius: 8px;
  background: linear-gradient(269.23deg, #f3a102 4.56%, #ef4901 87.54%);
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.16);
  box-shadow: $shadow-16;

  @include create-stacking-context;

  @include is-pointer-device {
    &:hover {
      opacity: 0.9;
    }
  }

  &__strong {
    font-size: 24px;
    font-weight: bold;
  }

  &__balloon {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: inline-block;
    width: 90px;
    margin: auto;
    padding: 2px 4px;
    font-size: 14px;
    font-weight: bold;
    color: $color-text-and-object-high-emphasis;
    border-radius: 14px;
    background-color: $color-okaneko-yellow;
    transform: translateY(-50%);
  }

  &::after {
    content: '';
    z-index: 0;
    position: absolute;
    top: 2px;
    left: 0;
    right: 0;
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: auto;
    transform: rotate(45deg);
    background-color: $color-okaneko-yellow;
  }
}

.carousel {
  overflow: hidden;
  &__badge {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 1;
  }
  &--notDesktop {
    position: relative;
    display: block;
    /* drop-shadow がクリッピングされないように padding を大きくとって margin で位置調整してる */
    padding: 32px 0 32px;
    margin-bottom: -16px;
    @include desktop-only {
      display: none;
    }
    @include create-stacking-context;
  }

  &--desktop {
    display: none;
    /* drop-shadow がクリッピングされないように padding を大きくとって margin で位置調整してる */
    margin: -12px 0 -16px;
    padding: 24px 0;
    @include desktop-only {
      display: block;
    }
  }
}

.catchcopy {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include desktop-only {
    align-items: flex-start;
  }
}
</style>
