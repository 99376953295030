<script lang="ts">
export type Props = {
  q: string;
  a: string;
};
</script>

<script setup lang="ts">
import IconChevronDown from '~theme/assets/icons/outline/chevron-down.svg?component';

defineProps<Props>();
const expanded = ref(false);
</script>

<template>
  <div
    :class="[$style.card, { [$style['card--shrinking']]: !expanded }]"
    @click="expanded = true"
  >
    <div
      :class="[
        $style.card__section,
        { [$style['card__section--active']]: expanded },
      ]"
    >
      <div :class="[$style.card__label, $style['card__label--q']]">Q</div>
      <div :class="$style.card__q">{{ q }}</div>
      <div v-show="!expanded" :class="$style.card__button">
        <IconChevronDown />
      </div>
    </div>
    <div v-show="expanded" :class="$style.card__section">
      <div :class="[$style.card__label, $style['card__label--a']]">A</div>
      <div :class="$style.card__a">{{ a }}</div>
    </div>
  </div>
</template>

<style module lang="scss">
.card {
  width: 100%;
  background-color: $color-surface-primary;
  border-radius: 8px;
  box-shadow: $shadow-4;
  overflow: hidden;
  &--shrinking {
    &:hover {
      cursor: pointer;
    }
  }

  &__section {
    display: flex;
    align-items: center;
    padding: 16px 24px;
    gap: 16px;
    align-items: center;

    @include mobile-only {
      padding: 16px 16px;
    }

    &--active {
      background-color: $color-surface-accent-primary-light;
    }
  }

  &__label {
    font-size: 28px;
    font-weight: bold;

    @include mobile-only {
      font-size: 24px;
    }

    &--q {
      color: $color-text-and-object-accent-primary;
    }

    &--a {
      color: $color-text-and-object-accent-red;
    }
  }

  &__q {
    flex: 1;
    font-size: 20px;
    font-weight: bold;
    @include mobile-only {
      font-size: 18px;
    }
  }

  &__a {
    flex: 1;
    font-size: 16px;
    line-height: 1.8em;
    @include mobile-only {
      font-size: 14px;
    }
  }

  &__button {
    background-color: $color-surface-primary;
    border-radius: 999vw;
    box-shadow: $shadow-4;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
