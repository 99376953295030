<script setup lang="ts">
import IndexAboutService from './IndexAboutService/IndexAboutService.vue';
import IndexAuthority from './IndexAuthority/IndexAuthority.vue';
import IndexBusinessModel from './IndexBusinessModel/IndexBusinessModel.vue';
import IndexFAQ from './IndexFAQ/IndexFAQ.vue';
import IndexHeader from './IndexHeader/IndexHeader.vue';
import IndexHero from './IndexHero/IndexHero.vue';
import IndexLinks from './IndexLinks/IndexLinks.vue';
import IndexNews from './IndexNews/IndexNews.vue';
import IndexPlanners from './IndexPlanners/IndexPlanners.vue';
import IndexProductLpLinks from './IndexProductLpLinks/IndexProductLpLinks.vue';
import IndexReviews from './IndexReviews/IndexReviews.vue';
import IndexSeminars from './IndexSeminars/IndexSeminars.vue';
import AppFooter from '~/components/organisms/AppFooter/AppFooter.vue';
import { graphql } from '~/gql';

const fragment = graphql(`
  fragment IndexTemplateSeminarsFragment on SeminarConnection {
    edges {
      node {
        ...IndexTemplateIndexSeminarsSeminarFragment
      }
    }
  }
`);

const viewerQuery = graphql(`
  query IndexViewerQuery {
    viewer2 {
      ... on ViewerQueryErrorPayload {
        error
      }
      ... on ViewerQuerySuccessPayload {
        result {
          ...IndexHeaderViewer
        }
      }
    }
  }
`);

const { data: viewerResult } = useAuthedQuery(viewerQuery);
const viewer = useClientSideOnlyComputed(undefined, () =>
  computed(() =>
    viewerResult.value?.viewer2.__typename === 'ViewerQuerySuccessPayload'
      ? viewerResult.value.viewer2.result
      : undefined
  )
);

const seminarQuery1 = graphql(`
  query IndexSeminarsQuery1 {
    household: filterSeminars(
      first: 1
      after: null
      filter: { isApplicable: true, categories: [FAMILY_BUDGET_IMPROVEMENT] }
    ) {
      ...IndexTemplateSeminarsFragment
    }
    lifePlanning: filterSeminars(
      first: 1
      after: null
      filter: { isApplicable: true, categories: [LIFE_PLANNING] }
    ) {
      ...IndexTemplateSeminarsFragment
    }
    educationExpenses: filterSeminars(
      first: 1
      after: null
      filter: { isApplicable: true, categories: [EDUCATION_EXPENSES] }
    ) {
      ...IndexTemplateSeminarsFragment
    }
    insurance: filterSeminars(
      first: 1
      after: null
      filter: { isApplicable: true, categories: [INSURANCE] }
    ) {
      ...IndexTemplateSeminarsFragment
    }
  }
`);

// FIXME: complexity limit 回避
const seminarQuery2 = graphql(`
  query IndexSeminarsQuery2 {
    assetManagement: filterSeminars(
      first: 1
      after: null
      filter: { isApplicable: true, categories: [ASSET_MANAGEMENT] }
    ) {
      ...IndexTemplateSeminarsFragment
    }
    mortgage: filterSeminars(
      first: 1
      after: null
      filter: { isApplicable: true, categories: [MORTGAGE] }
    ) {
      ...IndexTemplateSeminarsFragment
    }
    inheritance: filterSeminars(
      first: 1
      after: null
      filter: { isApplicable: true, categories: [INHERITANCE] }
    ) {
      ...IndexTemplateSeminarsFragment
    }
    savingTax: filterSeminars(
      first: 1
      after: null
      filter: { isApplicable: true, categories: [SAVING_TAX] }
    ) {
      ...IndexTemplateSeminarsFragment
    }
  }
`);

const { data: data1 } = useAsyncQuery(seminarQuery1);
const { data: data2 } = useAsyncQuery(seminarQuery2);

const seminars = (() => {
  const household = useComputedFragment(fragment, () => data1.value?.household);
  const lifePlanning = useComputedFragment(
    fragment,
    () => data1.value?.lifePlanning
  );
  const educationExpenses = useComputedFragment(
    fragment,
    () => data1.value?.educationExpenses
  );
  const insurance = useComputedFragment(fragment, () => data1.value?.insurance);
  const assetManagement = useComputedFragment(
    fragment,
    () => data2.value?.assetManagement
  );
  const mortgage = useComputedFragment(fragment, () => data2.value?.mortgage);
  const inheritance = useComputedFragment(
    fragment,
    () => data2.value?.inheritance
  );
  const savingTax = useComputedFragment(fragment, () => data2.value?.savingTax);

  return computed(() =>
    [
      household.value,
      lifePlanning.value,
      educationExpenses.value,
      insurance.value,
      assetManagement.value,
      mortgage.value,
      inheritance.value,
      savingTax.value,
    ].flatMap((v) => v?.edges.map((e) => e.node) ?? [])
  );
})();
</script>

<template>
  <div :class="$style.page">
    <IndexHeader :class="$style.page__header" :viewer="viewer" />
    <IndexHero />
    <IndexAuthority :class="$style.page__authority" />
    <IndexNews :class="$style.page__news" />
    <IndexAboutService id="about_service" :class="$style.page__about" />
    <IndexProductLpLinks id="consultation" :class="$style.page__consultation" />
    <IndexBusinessModel :class="$style.page__businessModel" />
    <IndexPlanners id="planners" :class="$style.page__planners" />
    <IndexReviews id="reviews" :class="$style.page__reviews" />
    <IndexFAQ id="faq" :class="$style.page__faq" />
    <IndexSeminars :seminars="seminars" :class="$style.page__seminars" />
    <IndexLinks :class="$style.page__links" />
    <AppFooter :class="$style.page__footer" />
  </div>
</template>

<style module lang="scss">
.page {
  background-color: $color-surface-primary;
  overflow: hidden;
  @include create-stacking-context;
  &__header {
    position: sticky;
    z-index: 1;
    top: 0;
  }

  &__authority {
    margin-top: 48px;
    @include mobile-only {
      margin-top: 0;
    }
  }
  &__news {
    margin-top: 48px;
    @include mobile-only {
      margin-top: 8px;
    }
  }
  &__about {
    margin-top: 64px;
    @include mobile-only {
      margin-top: 40px;
    }
  }
  &__consultation {
    margin-top: 64px;
    @include mobile-only {
      margin-top: 40px;
    }
  }
  &__businessModel {
    margin-top: 80px;
    @include mobile-only {
      margin-top: 24px;
    }
  }
  &__planners {
    margin-top: 64px;
    @include mobile-only {
      margin-top: 40px;
    }
  }
  &__reviews {
    margin-top: 64px;
    @include mobile-only {
      margin-top: 40px;
    }
  }
  &__faq {
    margin-top: 64px;
    @include mobile-only {
      margin-top: 40px;
    }
  }
  &__seminars {
    margin-top: 64px;
    @include mobile-only {
      margin-top: 40px;
    }
  }
  &__links {
    margin-top: 64px;
    @include mobile-only {
      margin-top: 40px;
    }
  }
  &__footer {
    margin-top: 40px;
  }
}
</style>
