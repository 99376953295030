<script setup lang="ts">
import { type FragmentType, graphql } from '@/gql';
import { format } from 'date-fns';
import { ja } from 'date-fns/locale';
import { SeminarApplicationStatus } from '~/gql/graphql';

const props = defineProps<{ seminar: FragmentType<typeof SeminarFragment> }>();

const SeminarFragment = graphql(`
  fragment IndexTemplateSeminarCardSeminarFragment on Seminar {
    id
    title
    description
    applicationFee
    schedules {
      id
      startedAt
      applicationStatus
    }
    speakers {
      id
      iconPath
      name
    }
  }
`);

const seminar = useComputedFragment(SeminarFragment, () => props.seminar);
const { buildImgixURL } = useImgixURL();

const iconUrl = computed(() => {
  if (seminar.value.speakers == undefined) {
    return null;
  }
  return buildImgixURL(seminar.value.speakers[0].iconPath, {
    width: 80,
    height: 80,
    quality: 100,
    faceFit: true,
  });
});

const firstApplicableSchedule = computed(() => {
  const schedule = seminar.value.schedules?.find(
    (elm) => elm.applicationStatus === SeminarApplicationStatus.Applicable
  );
  if (schedule == undefined) return null;
  return new Date(schedule.startedAt);
});

const yeardelta = computed(() => {
  if (firstApplicableSchedule.value == undefined) return null;
  const now = new Date();
  const toYYYYMMDD = (d: Date) =>
    d.getFullYear() * 10000 + d.getMonth() * 100 + d.getDate();
  return (toYYYYMMDD(firstApplicableSchedule.value) - toYYYYMMDD(now)) / 10000;
});

const hasMultipleSchedule = computed(
  () =>
    seminar.value.schedules.filter(
      (elm) => elm.applicationStatus === SeminarApplicationStatus.Applicable
    ).length > 1
);

const scheduleLabel = computed(() => {
  if (!firstApplicableSchedule.value) return null;
  return format(firstApplicableSchedule.value, 'E曜日 HH:mm〜 ', {
    locale: ja,
  });
});
</script>

<template>
  <NuxtLink :class="$style.card" :href="`/seminar/${seminar.id}`">
    <div :class="$style.card__iconWrapper">
      <img :class="$style.card__icon" :src="iconUrl ?? ''" />
    </div>
    <p :class="$style.card__title">{{ seminar.title }}</p>
    <p v-if="seminar.speakers?.length ?? 0 > 0" :class="$style.card__speaker">
      講師
      <span :class="$style['card__speaker--em']">{{
        seminar.speakers[0].name
      }}</span>
    </p>
    <div :class="[$style.card__description, $style.description]">
      <div
        v-if="!!firstApplicableSchedule"
        :class="$style.description__firstApplicableSchedule"
      >
        <p :class="$style.description__schedule">
          <span :class="$style.description__date"
            >{{ firstApplicableSchedule.getMonth() + 1 }}/{{
              firstApplicableSchedule.getDate()
            }}
          </span>
          <span
            v-if="!!yeardelta && yeardelta >= 1"
            :class="$style.description__year"
            >({{ firstApplicableSchedule.getFullYear() }}年)
          </span>
          <span :class="$style.description__time">{{ scheduleLabel }}</span>
          <span v-if="hasMultipleSchedule">他</span>
        </p>
        <span
          v-if="seminar.applicationFee === 0"
          :class="$style.description__fee"
          >無料</span
        >
        <span v-else :class="$style.description__fee"
          >{{ seminar.applicationFee }}円</span
        >
      </div>
      <p :class="$style.description__body">{{ seminar.description }}</p>
    </div>
  </NuxtLink>
</template>

<style lang="scss" module>
.card {
  color: $color-text-and-object-high-emphasis;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 12px;
  background-color: $color-surface-primary;
  padding: 24px;
  width: 360px;
  position: relative;
  text-align: start;

  &__iconWrapper {
    position: absolute;
    line-height: 0;
    @include create-stacking-context;

    &::before {
      position: absolute;
      content: '';
      display: block;
      top: 0;
      left: 0;
      width: 40px;
      height: 40px;
      z-index: 1;
      box-sizing: border-box;
      border-radius: 100vw;
      border: 2px solid $color-border-low-emphasis;
    }
  }

  &__icon {
    width: 40px;
    height: 40px;
    border-radius: 100vw;
  }

  &__title {
    line-height: 1em;
    padding: 10px 0;
    margin-left: 48px;
    width: fit-content;
    min-width: 224px;
    max-width: 264px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__speaker {
    margin-top: 16px;
    font-size: 16px;
    width: 100%;
    &--em {
      font-weight: bold;
    }
  }

  &__description {
    margin-top: 8px;
    flex: 1;
  }
}

.description {
  width: 100%;
  padding: 16px;

  background-color: $color-surface-secondary;
  border-radius: 8px;

  &__schedule {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 12px;
  }

  &__firstApplicableSchedule {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__date {
    color: $color-text-and-object-accent-primary;
    font-size: 20px;
    font-weight: bold;
  }

  &__year {
    color: $color-text-and-object-accent-primary;
    font-size: 16px;
    font-weight: bold;
  }

  &__fee {
    font-weight: bold;
    font-size: 12px;
  }

  &__time {
    font-weight: bold;
  }

  &__body {
    width: 100%;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    margin-top: 8px;
    font-weight: bold;
    font-size: 14px;
    overflow: hidden;
    height: calc(22px * 3);
  }
}
</style>
