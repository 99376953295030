<script setup lang="ts">
const props = defineProps<{ predicate: boolean }>();
const { predicate } = toRefs(props);

const mustShow = ref(true);

onMounted(() => {
  watch(predicate, (v) => (mustShow.value = v), { immediate: true });
});
</script>

<template>
  <slot v-if="mustShow" />
</template>
