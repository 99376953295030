<script setup lang="ts">
import IndexTemplate from '~/components/templates/IndexTemplate/IndexTemplate.vue';

definePageMeta({
  layout: 'plain',
});

useAppMeta({ urlPath: '/' });
</script>

<template>
  <IndexTemplate />
</template>
