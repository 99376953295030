<script setup lang="ts">
import DiagnosisButton from './DiagnosisButton.vue';
import IndexDrawer from './IndexDrawer.vue';
import ImgLogo from './logo.svg?component';
import UserThumbnail from 'theme/components/atoms/UserThumbnail.vue';
import FloatingPanel from 'theme/components/molecules/FloatingPanel.vue';
import FadeInTransition from 'theme/components/transitions/FadeInTransition.vue';
import IconExternalLink from '~/assets/icons/outline/external-link.svg?component';
import AppHeaderDrawerNew from '~/components/organisms/AppHeader/AppHeaderDrawer/AppHeaderDrawerNew.vue';
import { useHeaderSubNavForMember } from '~/components/organisms/AppHeader/useHeaderNavs';
import { type FragmentType, graphql } from '~/gql';
import ImgMenu from '~theme/assets/icons/outline/menu.svg?component';
import ButtonClose from '~theme/components/atoms/ButtonClose.vue';

const IndexHeaderViewerFragment = graphql(/* GraphQL */ `
  fragment IndexHeaderViewer on Viewer {
    ...AppHeaderDrawerNewViewer
    ...UseHeaderNavsViewerForMember
    nickname
    latestDiagnosis {
      result {
        personality {
          type
        }
      }
    }
  }
`);

const props = defineProps<{
  viewer?: FragmentType<typeof IndexHeaderViewerFragment>;
}>();

const viewer = useComputedFragment(
  IndexHeaderViewerFragment,
  () => props.viewer
);

const showingDrawer = ref(false);
const showingNavPanel = ref(false);

// FIXME: AppHeader の composition 使いまわし
const subNavs = useClientSideOnlyComputed([], () =>
  useHeaderSubNavForMember(viewer)
);

const links = [
  { location: { path: '.', hash: '#about_service' }, label: 'オカネコとは' },
  { location: { path: '.', hash: '#consultation' }, label: '相談内容' },
  { location: { path: '.', hash: '#planners' }, label: 'アドバイザー紹介' },
  { location: { path: '.', hash: '#reviews' }, label: '体験談' },
  { location: { path: '.', hash: '#faq' }, label: 'よくある質問' },
  { location: '/login', label: 'ログイン' },
];

const logout_ = import.meta.server ? () => {} : useLogout().logout;
const logout = () => {
  showingDrawer.value = false;
  showingNavPanel.value = false;
  logout_();
};
</script>

<template>
  <header :class="$style.container">
    <ImgLogo :class="$style.logo" />
    <div :class="$style.container__spacer" />
    <div :class="[$style.container__links, $style.links]">
      <NuxtLink v-for="l in links" :key="l.label" :to="l.location">{{
        l.label
      }}</NuxtLink>
    </div>
    <DiagnosisButton v-if="!viewer?.latestDiagnosis" :class="$style.button" />
    <div
      v-else
      :class="$style.container__profileWrapper"
      @mouseover="showingNavPanel = true"
      @mouseleave="showingNavPanel = false"
    >
      <div :class="$style.container__profile">
        <UserThumbnail
          :class="$style.container__thumbnail"
          :personality-type="viewer.latestDiagnosis.result.personality.type"
        />
        {{ viewer.nickname }}さん
      </div>
      <FadeInTransition :duration="100" :leaving-delay="100">
        <FloatingPanel
          v-if="showingNavPanel"
          :class="$style.container__panel"
          toggle-trigger="hover"
        >
          <div :class="$style.navPanel">
            <template v-for="(nav, index) in subNavs" :key="index">
              <div v-if="nav.condition" :class="$style.navPanel__container">
                <NuxtLink
                  :class="$style.navPanel__link"
                  :href="nav.href"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {{ nav.title }}
                </NuxtLink>
                <IconExternalLink
                  v-if="nav.isExternalLink"
                  :class="$style.navPanel__externalLinkIcon"
                />
              </div>
            </template>
            <div :class="$style.navPanel__container">
              <button :class="$style.navPanel__link" @click="logout">
                ログアウト
              </button>
            </div>
          </div>
        </FloatingPanel>
      </FadeInTransition>
    </div>
    <ImgMenu :class="$style.menu" @click="showingDrawer = true" />
    <IndexDrawer
      :showing-drawer="showingDrawer"
      @close-drawer="showingDrawer = false"
    >
      <AppHeaderDrawerNew
        v-if="!!viewer"
        :viewer="viewer"
        @close-drawer="showingDrawer = false"
        @logout="logout"
      />
      <div v-else :class="$style.drawerContentWithoutSession">
        <ButtonClose
          :class="$style.drawerContentWithoutSession__close"
          @click="showingDrawer = false"
        />
        <NuxtLink
          v-for="l in links"
          :key="l.label"
          :to="l.location"
          :class="$style.drawerContentWithoutSession__link"
          @click="showingDrawer = false"
          >{{ l.label }}</NuxtLink
        >
        <DiagnosisButton :class="$style.drawerContentWithoutSession__button" />
      </div>
    </IndexDrawer>
  </header>
</template>

<style module lang="scss">
.drawerContentWithoutSession {
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  gap: 12px;
  &__close {
    margin-bottom: 24px;
    width: 20px;
    padding: 4px;
    align-self: flex-end;
  }

  &__link {
    color: $color-text-and-object-high-emphasis;
    font-size: 16px;
    font-weight: bold;
    padding: 0 8px;
    &:hover {
      cursor: pointer;
    }
  }

  &__button {
    margin-top: 28px;
    text-align: center;
  }
}

.navPanel {
  padding: 32px;
  &__container {
    display: flex;
    flex-direction: celumn;
    align-items: center;
    gap: 4px;
    &:nth-child(n + 2) {
      margin-top: 8px;
    }
  }
  &__link {
    color: $color-text-and-object-high-emphasis;
  }
  &__externalLinkIcon {
    width: 14px;
  }
}

.container {
  padding: 16px 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: $color-surface-primary;

  @include mobile-only {
    padding: 12px 16px;
  }

  &__spacer {
    flex: 1;
  }

  &__links {
    margin-right: 40px;
  }
  &__thumbnail {
    width: 40px;
  }
  &__profile {
    gap: 12px;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
  }
  &__profileWrapper {
    position: relative;
    @include mobile-only {
      display: none;
    }
  }
  &__panel {
    z-index: 1;
    position: absolute;
    top: calc(100% - 16px);
    right: 24px;
    min-width: 240px;
  }
}

.links {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  gap: 32px;
  & > * {
    color: $color-text-and-object-high-emphasis;
    &:hover {
      cursor: pointer;
    }
  }

  @include mobile-only {
    display: none;
  }
}

.button {
  @include mobile-only {
    display: none;
  }
}

.logo {
  @include mobile-only {
    height: 18px;
  }
}

.menu {
  display: none;
  @include mobile-only {
    display: unset;
  }
}
</style>
