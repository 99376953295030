<script setup lang="ts">
import type { RouteLocationRaw } from '#vue-router';
import SectionContainer from '../SectionContainer/SectionContainer.vue';
import ProductLpCard, { type ProductLpCardProps } from './ProductLpCard.vue';
import imgAssetManagement from './asset_management.svg';
import imgHouseholdImprovements from './household_improvements.svg';
import imgHousing from './housing.svg';
import imgIdeco from './insurance.svg';
import imgNisa from './nisa.svg';
import imgRetirement from './retirement.svg';
import NuxtLinkFlat from '~theme/components/atoms/NuxtLinkFlat.vue';

const cards: {
  props: ProductLpCardProps;
  image: string;
}[] = [
  {
    props: {
      heading: '家計改善',
      listingItems: [
        '全然貯金ができないので、なんとかしたい',
        'ライフプランニングシミュレーションをしてみたい',
        'もっと節約をしたいのだけど、家計簿を見てもどこを改善すればいいのかわからない',
      ],
      buttonLabel: '家計相談についてもっと知りたい',
      location: '/products/finance-diagnosis',
    },
    image: imgHouseholdImprovements,
  },
  {
    props: {
      heading: '資産運用',
      listingItems: [
        '何から始めたらいいのかわからない',
        'NISAやiDeCoを始めてみたいが、ネットの情報だけで商品を選ぶのは不安',
        'お金を増やすために資産運用を始めたが、今の内容を客観的に見てほしい',
      ],
      buttonLabel: '資産運用相談についてもっと知りたい',
      location: '/products/asset',
    },
    image: imgAssetManagement,
  },
  {
    props: {
      heading: 'iDeCo',
      listingItems: [
        'iDeCoをやるべきなのかどうか？リスクやデメリットを知りたい',
        'iDeCoに毎月いくら掛けるのが良いか知りたい',
        '所得控除などの節税効果について知りたい',
      ],
      buttonLabel: 'iDeCoについてもっと知りたい',
      location: '/products/ideco',
    },
    image: imgIdeco,
  },
  {
    props: {
      heading: '住宅購入',
      listingItems: [
        '資産形成のために家が欲しいが、何から始めたらいいのかわからない',
        '自分に合った住宅ローンを選んでほしい',
        '今よりも金利が低いローンに借り換えした方がいいのか知りたい',
      ],
      buttonLabel: '住宅相談についてもっと知りたい',
      location: '/products/mortgage',
    },
    image: imgHousing,
  },
  {
    props: {
      heading: '老後資金',
      listingItems: [
        '資産の相続の対策方法を教えてほしい',
        '子供へ相続するにあたり、何を準備すればいいのかわからない',
        '実家の土地を相続するにあたり、どんな税金がかかるのか知りたい',
      ],
      buttonLabel: '老後資金についてもっと知りたい',
      location: '/products/retirement',
    },
    image: imgRetirement,
  },
  {
    props: {
      heading: '新NISA',
      listingItems: [
        '毎月貯金はできているけど、老後資金として十分かわからない',
        '子供が欲しいのだけど、教育費をまかなえるか不安',
      ],
      buttonLabel: '新NISAについてもっと知りたい',
      location: '/products/nisa',
    },
    image: imgNisa,
  },
];

const links: { label: string; location: RouteLocationRaw }[] = [
  { label: '投資信託', location: '/products/investmenttrust' },
  { label: '教育資金', location: '/products/education-fund' },
  { label: '結婚資金', location: '/products/marriage-cost' },
  { label: '出産費用', location: '/products/childbirth' },
  { label: 'ライフプラン', location: '/products/lifeplan' },
  { label: 'お金の勉強', location: '/products/training' },
  { label: '保険', location: '/products/insurance' },
];
</script>

<template>
  <SectionContainer
    heading="こんなことを相談しても良いのかな?"
    subheading="consultation"
  >
    <template #description>
      老後資金やライフプランニングのようなざっくりとしたお悩みから、資産運用や保険などの具体的な相談、<br />
      結婚や出産などのライフステージが変わったタイミングでの家計見直しなど、オカネコにお任せください。相談は何度しても無料です。
    </template>

    <div :class="$style.cards">
      <ProductLpCard v-for="c in cards" :key="c.props.heading" v-bind="c.props">
        <img :src="c.image" alt="" loading="lazy" />
      </ProductLpCard>
    </div>

    <h3 :class="$style.subheading">
      その他、気になるトピックで<br
        :class="$style.subheading__newLine"
      />ご相談ください
    </h3>
    <div :class="$style.links">
      <NuxtLinkFlat
        v-for="l in links"
        :key="l.label"
        :class="$style.links__item"
        :href="l.location"
        >{{ l.label }}</NuxtLinkFlat
      >
    </div>
  </SectionContainer>
</template>

<style module lang="scss">
.cards {
  margin-top: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;

  @include mobile-only {
    grid-template-columns: 1fr;
  }
}

.subheading {
  font-size: 20px;
  line-height: 1.8;
  margin-top: 24px;
  text-align: center;

  &__newLine {
    display: none;
    @include mobile-only {
      display: unset;
    }
  }
}

.links {
  width: 100%;
  display: flex;
  gap: 16px;
  margin-top: 24px;
  &__item {
    flex: 1;
    padding: 8px 0;
  }

  @include mobile-only {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
  }
}
</style>
