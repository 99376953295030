<template>
  <div :class="$style.badge">
    <span :class="$style.badge__text">2024年6月</span>
    <span :class="$style['badge__text--em']">診断</span>
    <span :class="$style.badge__text">リニューアル</span>
  </div>
</template>

<style lang="scss" module>
.badge {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  background-color: $color-label-red;
  color: $color-text-and-object-high-emphasis-inverse;
  border-radius: 100vh;
  gap: 2px;
  &__text {
    line-height: 13px;
    font-size: 13px;
    font-weight: bold;
    &--em {
      line-height: 29px;
      font-size: 29px;
      font-weight: 900;
    }
  }
}
</style>
