<script setup lang="ts">
import SectionContainer from '../SectionContainer/SectionContainer.vue';
import SeminarCard from './SeminarCard.vue';
import { useElementSize } from '@vueuse/core';
import { type FragmentType, graphql } from '~/gql';
import NuxtLinkFlat from '~theme/components/atoms/NuxtLinkFlat.vue';

const props = defineProps<{
  seminars: FragmentType<typeof SeminarFragment>[];
}>();

const SeminarFragment = graphql(`
  fragment IndexTemplateIndexSeminarsSeminarFragment on Seminar {
    id
    ...IndexTemplateSeminarCardSeminarFragment
  }
`);

const seminars = useComputedFragment(SeminarFragment, () => props.seminars);
const section = ref<HTMLElement>();
const { width: sectionWidth } = useElementSize(section);
const itemsToShow = computed(() => Math.max(1, sectionWidth.value / 380));
const needToAnimate = computed(() => itemsToShow.value < seminars.value.length);
const { isMobile } = useDeviceType();
const snapAlign = computed(() => (isMobile.value ? 'center' : 'center-even'));
</script>

<template>
  <div ref="section" :class="$style.container">
    <SectionContainer heading="セミナー" subheading="seminar" />
    <Carousel
      v-if="needToAnimate"
      :class="$style.carousel"
      :autoplay="3000"
      :transition="800"
      :items-to-show="itemsToShow"
      :snap-align="snapAlign"
      wrap-around
      mouse-drag
      touch-drag
      pause-autoplay-on-hover
    >
      <Slide
        v-for="(s, idx) in seminars"
        :key="`slide:${idx}:${s.id}`"
        :class="$style.carousel__slide"
      >
        <SeminarCard :class="$style.carousel__card" :seminar="s" />
      </Slide>
    </Carousel>
    <div v-else :class="$style['carousel--static']">
      <SeminarCard
        v-for="(s, idx) in seminars"
        :key="`card:${idx}:${s.id}`"
        :class="$style.carousel__card"
        :seminar="s"
      />
    </div>
    <NuxtLinkFlat :class="$style.button" href="/seminar"
      >セミナーをもっと見る</NuxtLinkFlat
    >
  </div>
</template>

<style module lang="scss">
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.carousel {
  width: 100%;
  margin-top: -32px;
  &__slide {
    padding: 12px 0;
  }
  &__card {
    box-shadow: $shadow-8;
    min-height: 100%;
  }
  &--static {
    display: flex;
    gap: 24px;
    justify-content: center;
    align-items: stretch;
  }
}

.button {
  margin-top: 24px;
  padding: 8px 16px;
}
</style>
