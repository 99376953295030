<script setup lang="ts">
import SectionContainer from '../SectionContainer/SectionContainer.vue';
import { mobileMaxWidth } from 'theme/assets/styles/export.module.scss';

const authorityAlt =
  '国内最大級の家計診断・相談サービス 体験者数100万人突破 クチコミ平均4.9を獲得';
const mediaAlt =
  '受賞歴: TOKYO FINANCIAL AWARD 東京金融賞 メディア掲載実績: 「JIJI Financial Solutions」2024年3月22日掲載、「BCN+R」2023年7月18日掲載';
</script>

<template>
  <SectionContainer>
    <div :class="$style.authority">
      <picture>
        <source
          srcset="./authority-mobile.svg"
          :media="`(max-width: ${mobileMaxWidth}px)`"
        />
        <img src="./authority.svg" :alt="authorityAlt" loading="lazy" />
      </picture>
      <picture>
        <source
          srcset="./media-mobile.svg"
          :media="`(max-width: ${mobileMaxWidth}px)`"
        />
        <img src="./media.svg" :alt="mediaAlt" loading="lazy" />
      </picture>
    </div>
  </SectionContainer>
</template>

<style module lang="scss">
img {
  vertical-align: top;
}

.authority {
  display: flex;
  gap: 24px;
  @include mobile-only {
    flex-direction: column;
    gap: 8px;
  }
}
</style>
